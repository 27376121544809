/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

/* Colors */
$blacklusion-highlight: #48cae4;
$blacklusion-secondary: #999;

.footer {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-flow: row wrap;
  padding: 10px;
  color: white;
  background-color: black;
  font-family: "Roboto Mono", monospace;

  ul {
    list-style: none;
    padding-left: 0;
  }
  li {
    line-height: 1.5em;
  }

  a {
    color: $blacklusion-secondary;
  }
  a:hover {
    color: $blacklusion-highlight;
    text-decoration: none;
  }
}
.footer > * {
  padding-top: 50px;
  flex: 1 100%;
}

.footer-left {
  margin-right: 1.25em;
  text-align: center;

  h1 {
    font-size: 1.7em;
    font-weight: bolder;
    color: white;
  }

  // todo: change thickness of underline e.g. in firefox
  a:hover {
    text-decoration: underline;
  }

  .socials {
    text-align: center;

    a {
      font-size: 20px;
      display: inline-block;
      margin-right: 10px;
      margin-left: 10px;
    }
  }
}

.footer-right {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-flow: row wrap;

  h2 {
    font-size: 1em;
    text-align: left;
  }

  .link-list {
    a {
      text-decoration: none;
      font-size: 1em;
    }
  }
}
.footer-right > * {
  flex: 1 50%;
  margin-right: 1.25em;
}

.footer-bottom {
  text-align: center;
  color: $blacklusion-secondary;
  font-size: 0.6em;
  a {
  text-decoration: underline;
  }
}

@media screen and (min-width: 600px) {
  .footer-right > * {
    flex: 1;
  }
  .footer-left {
    flex: 1 0;
  }
  .footer-right {
    flex: 2 0;
  }
}

@media (max-width: 600px) {
  .footer {
    padding: 15px;
  }

  .footer-right {
    text-align: center;
  }

  .footer-right h2 {
    text-align: center;
    margin-top: 50px;
  }

  .footer-right ul {
    padding-top: 0;
  }
}
