html {
  background-color: black;
}

.App {
  background-color: #0f0f10;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: white;
}
