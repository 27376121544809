/* base */
@import "base/reset";
@import "base/app";
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import "../../node_modules/bootstrap-scss/bootstrap";

@import "../components/blacklusion-footer/footer";

html {
  background-color: black;
}

body {
  background-color: black;
}

.app {
  background-color: #08080b;

  em {
    color: white;
    text-shadow: 0 0 10px white;
  }

  .blacklusion-logo {
    height: 15vmin;
    pointer-events: none;
    align-self: start;
    margin-left: 5%;
  }

  .background-effect {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;

    h1 {
      margin-left: 5%;
      color: #cbcbcd;
      font-size: 3em;
      @include media-breakpoint-up(lg) {
        font-size: 4em;
      }
      font-family: "roboto mono", monospace;
      font-weight: bold;
    }
  }

  .section {
    min-height: 70vh;

    @include media-breakpoint-up(lg) {
      min-height: 100vh;
    }
    display: flex;

    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-family: "roboto mono", monospace;
    font-weight: bold;

    .sub-section {
      margin-top: 50px;

      @include media-breakpoint-up(lg) {
        margin-top: 200px;
      }
    }

    .small {
      width: 60%;
    }

    .center {
      text-align: center;
    }

    .left {
      text-align: start;
      align-self: start;
      -webkit-align-self: flex-start;
    }

    .right {
      text-align: end;
      align-self: end;
      -webkit-align-self: flex-end;
    }

    .last {
      margin-bottom: 200px;
    }

    .first {
      margin-top: 200px;
    }

    h1 {
      color: white;
      font-size: 2.2em;
    }

    h2 {
      color: white;
      font-size: 1.8em;
    }

    p {
      color: #cbcbcd;
      margin-top: 20px;
      font-size: 1em;
      text-align: justify;
      line-height: 1.5em;
    }

    a:link {
      color: #cbcbcd;
      text-decoration: none;
    }

    a:hover {
      color: white;
      text-decoration: none;
      text-shadow: 0 0 10px white;
    }

    @include media-breakpoint-up(lg) {
      h1 {
        font-size: 3.5em;
      }

      h2 {
        font-size: 2.5em;
      }

      p {
        font-size: 2em;
      }
    }
  }
}
